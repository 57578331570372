"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasPermission = void 0;
const permissions = [
    'write_code',
    'write_project',
    'comment',
    'read',
    'none',
];
/**
 * Whether the given permission is permitted compared to the required permission.
 * We use a simple model for this, where if you have a top permission it will be considered
 * that you have all other permissions. This same list is saved on the server, and this needs
 * to keep in sync with that.
 * @param permission Permission to test
 * @param requiredPermission Required permission to pass the test
 */
function hasPermission(permission, requiredPermission) {
    return (permissions.indexOf(permission) <= permissions.indexOf(requiredPermission));
}
exports.hasPermission = hasPermission;
