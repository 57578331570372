"use strict";Object.defineProperty(exports,"__esModule",{value:!0}),exports.useUniqueId=void 0;var _react=require("react"),counter=0,useUniqueId=exports.useUniqueId=function(){return(0,_react.useMemo)(function(){return++counter},[])};/* Template icons use <linearGradient/>, <radialGradient/>, <filter/>,
 * <clipPath/> and potentially other svg definitions which require
 * a global identifier that is referenced through `"url(#defId)"`
 *
 * We need a unique global identifier to avoid name collision when
 * an icon is rendered more than once.
 * https://github.com/codesandbox/codesandbox-templates/issues/4
 */ /** Renerate a unique identifier on every mount of a component
 *
 * That is achieved by the useMemo's second argument [] which says, execute
 * the function and return new value on every mount
 */