var SandboxExecutor = /** @class */ (function () {
    function SandboxExecutor() {
    }
    SandboxExecutor.prototype.initialize = function () {
        return Promise.resolve();
    };
    SandboxExecutor.prototype.setup = function () {
        return Promise.resolve();
    };
    SandboxExecutor.prototype.dispose = function () {
        return Promise.resolve();
    };
    SandboxExecutor.prototype.updateFiles = function () { };
    SandboxExecutor.prototype.emit = function () { };
    SandboxExecutor.prototype.on = function () { };
    return SandboxExecutor;
}());
export { SandboxExecutor };
